import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getInvoiceDocument, getInvoice, Invoice, InvoiceDocumentRequest, InvoiceRequest } from './invoiceAPI';
import { invoiceExistsAsync, logout } from '../login/loginSlice';

export interface InvoiceState {
    id: string;
    isAtivoCore: boolean;
    invoice?: Invoice;
    issuerName?: string;
    issuerOrganizationNumber?: string;
    isLoading: boolean;
    error?: boolean;
    documentError?: boolean;
    selectedDocument?: string;
    selectedDocumentType?: string;
}

export interface InvoiceDocument {
    documentId: string;
    documentType: string;
    documentDate: string;
}

const initialState: InvoiceState = {
    id: '',
    isAtivoCore: false,
    invoice: undefined,
    isLoading: false,
    error: undefined,
    documentError: undefined,
    selectedDocument: undefined,
};

export const getInvoiceAsync = createAsyncThunk('invoice/getInvoice', async (request: InvoiceRequest) => {
    const response = await getInvoice(request);
    return response;
});

export const getInvoiceDocumentAsync = createAsyncThunk('invoice/invoicedocument', async (request: InvoiceDocumentRequest) => {
    const response = await getInvoiceDocument(request);
    return response;
});

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setStoredInvoiceState: (state, action) => {
            state = action.payload;
        },
        setSelectedDocumentType: (state, action) => {
            state.selectedDocumentType = action.payload;
        },
        clearSelectedDocument: (state) => {
            state.selectedDocument = undefined;
            state.selectedDocumentType = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(invoiceExistsAsync.pending, (state) => {
                state.invoice = undefined;
                state.id = '';
                state.isAtivoCore = false;
            })
            .addCase(invoiceExistsAsync.rejected, (state) => {
                state.invoice = undefined;
                state.id = '';
                state.isAtivoCore = false;
            })
            .addCase(invoiceExistsAsync.fulfilled, (state, action) => {
                state.invoice = undefined;
                if (action.payload.success && action.payload.invoiceId) {
                    state.id = action.payload.invoiceId;
                    state.isAtivoCore = action.payload.isAtivoCore;
                } else {
                    state.id = '';
                    state.isAtivoCore = false;
                }
            })
            .addCase(getInvoiceAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInvoiceAsync.rejected, (state) => {
                state.isLoading = false;
                state.error = true;
            })
            .addCase(getInvoiceAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.success && action.payload.invoice) {
                    state.invoice = action.payload.invoice;
                    state.issuerName = action.payload.issuerName;
                    state.issuerOrganizationNumber = action.payload.issuerOrganizationNumber;
                } else {
                    state.error = true;
                }
            })
            .addCase(logout, () => {
                return initialState;
            })
            .addCase(getInvoiceDocumentAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInvoiceDocumentAsync.rejected, (state) => {
                state.isLoading = false;
                state.documentError = true;
            })
            .addCase(getInvoiceDocumentAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedDocument =
                    action.payload.base64Encoded && action.payload.base64Encoded.length > 0
                        ? 'data:application/octet-stream;base64,' + action.payload.base64Encoded
                        : undefined;
                state.documentError = !(action.payload.base64Encoded && action.payload.base64Encoded.length > 0);
            });
    },
});

export const { setStoredInvoiceState, setSelectedDocumentType, clearSelectedDocument } = invoiceSlice.actions;

export const selectInvoice = (state: RootState) => state.invoice;

export default invoiceSlice.reducer;
