import { useSelector } from 'react-redux';
import { selectInvoice } from '../invoiceSlice';
import { GlobalContent, InvoiceContentDataAttributes } from '../../../utils/contentApi';
import { RenderImage } from '../../../components/image';
import { formatDate, formatCurrency } from '../../../utils/formatting';

export default function Details({ invoiceContent, globalContent }: { invoiceContent: InvoiceContentDataAttributes | null, globalContent: GlobalContent | undefined; }) {

    const invoiceState = useSelector(selectInvoice);


    return (
        <>
            {invoiceState.invoice && (
                <div className="grid grid-cols-2 lg:grid-cols-[auto__1fr] gap-2 mx-8">
                    <div className="col-span-2">{invoiceContent?.recipient}</div>
                    <div className="col-span-2 font-semibold">{invoiceState.invoice.recepient.name}</div>
                    <div className="col-span-2">{invoiceContent?.issuer}</div>
                    <div className="col-span-2 font-semibold">{invoiceState.issuerName}</div>

                    <div className="col-span-2 mt-6">
                        <h3>{invoiceContent?.info_sub_header}</h3>
                    </div>

                    <div>{invoiceContent?.invoice_number}</div>
                    <div className='font-bold'>{invoiceState.invoice.invoiceNumber}</div>
                    <div>{invoiceContent?.ocr}</div>
                    <div className='whitespace-nowrap font-bold'>
                        {invoiceState.invoice.ocr}
                        <button onClick={() => { navigator.clipboard.writeText(invoiceState?.invoice?.ocr!)}} ><RenderImage media={globalContent?.data?.attributes?.copy} className="w-4 h-4 inline-block ml-2 hover:opacity-60" /></button>
                    </div>

                    <div>{invoiceContent?.invoice_date}</div>
                    <div className='font-bold'>{formatDate(invoiceState.invoice.invoiceDate)}</div>

                    <div>{invoiceContent?.due_date}</div>
                    <div className='font-bold'>{formatDate(invoiceState.invoice.invoiceExpiryDate)}</div>

                    <div>{invoiceContent?.payment_number_type}</div>
                    <div className='font-bold'>{invoiceContent?.payment_number_value}</div>

                    <div className='font-bold'>{invoiceContent?.remaining_amount}</div>
                    <div className='font-bold'>{formatCurrency(invoiceState.invoice.remainingToPay)}</div>
                </div>
            )}
        </>
    );
}
