import { InvoiceState } from "../features/invoice/invoiceSlice";

export function downloadPdf(invoiceState: InvoiceState) {
  if (invoiceState.selectedDocument) {
      const linkSource = invoiceState.selectedDocument;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `${invoiceState.selectedDocumentType}-${invoiceState.invoice?.recepient.name}.pdf`;
      downloadLink.click();
  }
}
