export interface OidcConfig {
    authority: string;
    client_id: string;
    redirect_uri: string;
    post_logout_redirect_uri: string;
    automaticSilentRenew: boolean;
    onSigninCallback: () => void;
}
export const oidcConfig: OidcConfig = {
    client_id: 'recipientportal',
    redirect_uri: window.location.origin,
    post_logout_redirect_uri: window.location.origin,
    onSigninCallback: () => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
    authority: process.env.REACT_APP_OIDC_AUTHORITY!,
    automaticSilentRenew: false,
};
