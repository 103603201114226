import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import invoiceReducer from '../features/invoice/invoiceSlice';
import loginReducer from '../features/login/loginSlice';
import { loginApi } from '../features/login/loginAPI';
import { contentApi } from '../utils/contentApi';

export const store = configureStore({
    reducer: {
        invoice: invoiceReducer,
        login: loginReducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [contentApi.reducerPath]: contentApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loginApi.middleware).concat(contentApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
