import { useMemo } from 'react';
import { RenderImage } from '../../components/image';
import Button from '../../components/button/Index';
import { useGlobalContentQuery, useLoginContentQuery, ResolveImageUrl } from '../../utils/contentApi';
import { useAppDispatch } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import { logout } from '../login/loginSlice';
import ReactMarkdown from 'react-markdown';

export default function Unauthorized() {
    const globalContentResponse = useGlobalContentQuery();
    const loginContentResponse = useLoginContentQuery();
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const loginStyleBgImg = useMemo(() => {
        if (loginContentResponse.data && loginContentResponse.isSuccess) {
            // get the attributes with the latest published date
            const latestContent = loginContentResponse.data.data;
            const imgUrl = ResolveImageUrl(latestContent?.attributes.bg_img);
            const divStyle = imgUrl.exists
                ? {
                      backgroundImage: 'url(' + imgUrl.url + ')',
                  }
                : null;
            return divStyle;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginContentResponse.data, loginContentResponse.isSuccess]);
    const handleLogout = () => {
        dispatch(logout());
        auth.signoutRedirect();
    };
    return (
        <div className="grid md:grid-cols-2 absolute inset-0 place-items-center">
            <div className="grid grid-rows-[__84px__1fr__] bg-white h-full w-full">
                <div className="my-10 mx-6">
                    <RenderImage media={globalContentResponse?.data?.data?.attributes?.fg_logo.data} imageVariant={0} />
                </div>
                <div className="grid place-items-center">
                    <div className="p-6 h-full md:w-2/4 mx-auto ">
                        <div className="grid place-content-center items-center h-full md:h-4/5 w-full">
                            <ReactMarkdown className="whitespace-pre-wrap">{loginContentResponse.data?.data?.attributes?.bankid_ununathorized!}</ReactMarkdown>
                            <div className="mt-4">
                                <Button onClick={handleLogout} className="place-self-center ">
                                    {loginContentResponse.data?.data?.attributes?.retry_button_text}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block h-full w-full  bg-fg-primary">
                {loginStyleBgImg && <div className="h-full w-full bg-no-repeat bg-cover opacity-20" style={loginStyleBgImg}></div>}
            </div>
        </div>
    );
}
