import { useState } from 'react';

export default function MobileMenuDropDown({ handleLogout, logoutBtnText  }: { handleLogout: any, logoutBtnText: string | undefined }) {
    const [isNavOpen, setIsNavOpen] = useState(false);
    return (
        <div className="grid sm:hidden grid-row-2 my-auto">
            <div>
                <button
                    className="w-20 h-20 focus:outline-none text-white"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsNavOpen(!isNavOpen);
                    }}
                >
                    <div className="relative">
                        <span className={`block absolute  h-0.5 w-5 bg-current transform duration-500 ${isNavOpen ? 'rotate-45' : '-translate-y-1.5'}`}></span>
                        <span className={`block absolute  h-0.5 w-5 bg-current transform duration-500 ${isNavOpen ? 'opacity-0' : ''}`}></span>
                        <span className={`block absolute  h-0.5 w-5 bg-current transform duration-500 ${isNavOpen ? '-rotate-45' : 'translate-y-1.5'}`}></span>
                    </div>
                </button>
            </div>

            <div className={`grid mt-14 right-8 absolute transform duration-500 ${isNavOpen ? 'opacity-1' : 'opacity-0'}`}>
                <button className="text-white" onClick={() => handleLogout()}>
                    {logoutBtnText}
                </button>
            </div>
        </div>
    );
}
