import { ResolveImageUrl } from '../../utils/contentApi';

export const RenderImage = ({ media, className, imageVariant = 0 }: { media: any; className?: string; imageVariant? : number }): JSX.Element => {
    let image = ResolveImageUrl(media, imageVariant);
    if (image.exists) {
        return (
            <>
                <img src={image.url!} alt={image.title} title={image.title} className={className} />
            </>
        );
    }
    return <></>;
};
