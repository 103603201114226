import { InvoiceStatus, Payment } from './invoiceAPI';
import { HistoryState, HistoryStatDynamicDataAttributes } from './../../utils/contentApi';
import { InvoiceState } from './invoiceSlice';
import { formatCurrency } from '../../utils/formatting';

const showState = (state: InvoiceStatus | Payment, historyStateData: HistoryState): HistoryStatDynamicDataAttributes | undefined => {
    const isInvoiceStatus = (state as InvoiceStatus).state;

    const statusComponent = isInvoiceStatus ? 'status.state-with-icon' : 'status.betalningsinformation';
    const status = isInvoiceStatus ? (state as InvoiceStatus).state : (state as Payment).paymentMethod.name;
    const item = isInvoiceStatus
        ? historyStateData?.data.attributes.hs?.find(
              (element) => element.__component === statusComponent && element.status.data.attributes.skynet_status === status
          )
        : historyStateData?.data.attributes.hs?.find((element) => element.__component === statusComponent && element.status.data.attributes.status === status);

    return item;
};

export interface HistoryStatesWithPayment {
    state: string;
    timestamp: Date;
    invoiceChangeAmount?: string;
    item?: HistoryStatDynamicDataAttributes;
    itemType: ItemType;
    index: number;
}
export enum ItemType {
    InvoiceState = 'invoiceState',
    Payment = 'payment',
}

export const getStatesWithPayments = (invoiceState: InvoiceState, historyStateData: HistoryState): HistoryStatesWithPayment[] | null => {
    const states = new Array<HistoryStatesWithPayment>();
    if (invoiceState.invoice?.statuses) {
        invoiceState.invoice?.statuses.forEach((status, index) => {
            const item = showState(status, historyStateData);
            if (item) {
                states.push({
                    state: status.state,
                    timestamp: new Date(status.timestamp),
                    item: item,
                    itemType: ItemType.InvoiceState,
                    index: index,
                });
            }
        });
    }
    if (invoiceState.invoice?.payments) {
        var statusLength = invoiceState.invoice?.statuses?.length ?? 0; // small hack to get the payments with same date as the last invoice state to appear before the last invoice state
        invoiceState.invoice?.payments.forEach((status, index) => {
            const item = showState(status, historyStateData);
            if (item) {
                states.push({
                    state: status.paymentMethod.name,
                    timestamp: new Date(status.paymentDate),
                    item: item,
                    itemType: ItemType.Payment,
                    invoiceChangeAmount: formatCurrency(Number(status.amount) * -1),
                    index: index + statusLength,
                });
            }
        });
    }
    if (states.length > 0) {
        return states
            .sort((a, b) => {
                return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime() || a.index - b.index;
            })
            .reverse();
    }
    return null;
};
