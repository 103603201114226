import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Invoice from './features/invoice/Invoice';
import Login from './features/login/Login';
import Unauthorized from './features/unauthorized/Unauthorized';
function App() {
    return (
    <Router>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/invoice/:invoiceObject" element={<Invoice />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
    </Router>
    )
}

export default App;
