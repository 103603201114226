import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LoginContentDataAttributes, ResolveImageUrl, useGlobalContentQuery, useLoginContentQuery } from '../../utils/contentApi';
import Spinner from '../../utils/Spinner';
import { LoginRequest } from './loginAPI';
import { selectLogin, invoiceExistsAsync, setBankIdLogin, setLoginComplete, setLoginFailed } from './loginSlice';
import { BankIdLogin, DefaultLoginProps, LoginProps } from '@factoringgruppen/fg.ui.components';
import { useAuth } from 'react-oidc-context';
import { setStoredInvoiceState, selectInvoice } from '../invoice/invoiceSlice';
import Button from '../../components/button/Index';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const navigate = useNavigate();
    const loginState = useSelector(selectLogin);
    const invoiceState = useAppSelector(selectInvoice);
    const [formState, setFormState] = useState<LoginRequest>({
        ocr: '',
        zipCode: '',
    });
    const [canLogin, setCanLogin] = useState(false);
    const [loginContent, setLoginContent] = useState<null | LoginContentDataAttributes>(null);
    const [loginStyleBgImg, setLoginStyleBgImg] = useState<any>('');
    const [fgLogoUrl, setFgLogoUrl] = useState<string>('');
    const [bankIdProps, setBankIdProps] = useState<LoginProps | null | undefined>();
    const loginContentResponse = useLoginContentQuery();
    const globalContentResponse = useGlobalContentQuery();

    useEffect(() => {
        auth.removeUser();
      }, []);

    useEffect(() => {
        if (formState && formState.ocr && formState.zipCode) {
            setCanLogin(true);
        } else {
            setCanLogin(false);
        }
    }, [formState]);

    useMemo(() => {
        if (loginContentResponse.data && loginContentResponse.isSuccess) {
            // get the attributes with the latest published date
            const latestContent = loginContentResponse.data.data;
            setLoginContent(latestContent?.attributes ?? null);

            const imgUrl = ResolveImageUrl(latestContent?.attributes.bg_img);
            const divStyle = imgUrl.exists
                ? {
                      backgroundImage: 'url(' + imgUrl.url + ')',
                  }
                : null;
            setLoginStyleBgImg(divStyle);
            setBankIdProps({
                ...DefaultLoginProps,
                description: latestContent.attributes.bankid_help_text,
                orString: latestContent?.attributes.bankid_or_text,
                buttonStringOtherDevice: latestContent?.attributes.bankid_button_text,
                buttonStringSameDevice: latestContent?.attributes.bankid_button_text,
                onBankIdComplete: (id) => dispatch(setBankIdLogin(id)),
                bankIdEndPoints: {
                    pollEndPoint: {
                        ...DefaultLoginProps.bankIdEndPoints.pollEndPoint,
                        url: process.env.REACT_APP_BANKID_POLL_ENDPOINT!,
                    },
                    initiateEndPoint: {
                        ...DefaultLoginProps.bankIdEndPoints.initiateEndPoint,
                        url: process.env.REACT_APP_BANKID_START_ENDPOINT!,
                    },
                    generateRollingQrCode: {
                        ...DefaultLoginProps.bankIdEndPoints.generateRollingQrCode,
                        url: process.env.REACT_APP_BANKID_GENERATE_ROLLING_QR_CODE_URL!,
                    },
                },
                redirectUrl: process.env.REACT_APP_BANKID_REDIRECT_URL!,
            });
        } else {
            setLoginContent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginContentResponse.data, loginContentResponse.isSuccess]);

    useEffect(() => {
        if (globalContentResponse.data && globalContentResponse.isSuccess) {
            const fgLogo =
                globalContentResponse.data.data.attributes?.fg_logo.data.find((data: { attributes: { name: string | string[] } }) =>
                    data.attributes.name.includes('green')
                ) ?? globalContentResponse.data.data.attributes?.fg_logo.data[0];

            const imgUrl = fgLogo.attributes.url.startsWith('/') ? fgLogo.attributes.url.slice(1) : fgLogo.attributes.url;
            setFgLogoUrl(imgUrl);
        } else {
            setFgLogoUrl('');
        }
    }, [globalContentResponse.data, globalContentResponse.isSuccess]);

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setFormState((prev) => ({ ...prev, [name]: value }));

    const login = () => {
        dispatch(invoiceExistsAsync(formState));
    };

    const checkForSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (canLogin && e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            login();
        }
    };

    useEffect(() => {
        const authenticate = async () => {
            if (loginState.status !== 'success') return;
            if (loginState.status === 'success' && !auth?.isLoading && auth?.user === null && invoiceState.id !== null) {
                sessionStorage.setItem('invoicestate', JSON.stringify(loginState));
                try {
                    await auth.signinSilent({ extraQueryParams: { invoiceId: invoiceState.id || '', tid: loginState.transactionId || '' } });
                } catch (e: any) {
                    dispatch(setLoginFailed());
                }
            }

            if (loginState.status === 'success' && auth.user != null) {
                let storedState = sessionStorage.getItem('invoicestate');
                if (storedState) {
                    dispatch(setStoredInvoiceState(JSON.parse(storedState)));
                }
                dispatch(setLoginComplete());
                navigate('/invoice/' + btoa(JSON.stringify({ id: invoiceState.id, ocr: formState.ocr, isAtivoCore: invoiceState.isAtivoCore })), {
                    replace: true,
                });
            }
        };

        authenticate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.isLoading, loginState.status, auth.activeNavigator]);

    function isIosWebView() {
        return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    }

    return (
        <div className="grid md:grid-cols-2 absolute inset-0 place-items-center">
            <div className="grid grid-rows-[__84px__1fr__] bg-white h-full w-full">
                <div className="my-10 mx-6">{fgLogoUrl && <img src={fgLogoUrl} alt="Factoringgruppens logotyp"></img>}</div>
                <div className="grid place-items-center">
                    <div className="p-6 h-full md:w-2/4 ">
                        {loginContentResponse.isLoading || auth?.isLoading ? (
                            <div className="grid place-content-center items-center h-full md:h-4/5 w-full ">
                                <Spinner />
                            </div>
                        ) : loginContent ? (
                            <div className="grid place-content-center items-center h-full md:h-4/5 w-full">
                                <h1>{loginContent.header}</h1>
                                {loginState.status === 'needsExternalLogin' ? (
                                    <div className="bankId">
                                        <BankIdLogin
                                            handleCompleted={() => {}}
                                            title={''}
                                            description={''}
                                            onBankIdComplete={(id) => dispatch(setBankIdLogin(id))}
                                            bankIdEndPoints={bankIdProps?.bankIdEndPoints}
                                            redirectUrl={bankIdProps?.redirectUrl}
                                            orString={'eller om du vill öppna BankID på den här enheten'}
                                            buttonStringSameDevice={isIosWebView() ? 'Logga in' : 'Öppna BankID på denna enheten'}
                                            preventRedirect={isIosWebView()}
                                            styles={{ useRoundedCorner: true }}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <label htmlFor="ocr">
                                            <ReactMarkdown>{loginContent.ocr_field}</ReactMarkdown>
                                        </label>
                                        <input
                                            autoFocus
                                            type="number"
                                            name="ocr"
                                            id="ocr"
                                            onChange={handleChange}
                                            onKeyDown={checkForSubmit}
                                            value={formState.ocr}
                                        />
                                        <label htmlFor="zipCode" className="mt-4">
                                            <ReactMarkdown>{loginContent.zipcode_field}</ReactMarkdown>
                                        </label>
                                        <input
                                            type="number"
                                            name="zipCode"
                                            id="zipCode"
                                            onChange={handleChange}
                                            onKeyDown={checkForSubmit}
                                            value={formState.zipCode}
                                        />

                                        <div className=" mt-4">
                                            <Button disabled={!canLogin || loginState.isLoading} className="place-self-center " onClick={login}>
                                                {loginContent.log_in_btn}
                                            </Button>
                                        </div>

                                        {loginState.status === 'error' && (
                                            <div className="pt-4">
                                                <ReactMarkdown>{loginContent.generic_error}</ReactMarkdown>
                                            </div>
                                        )}
                                        {loginState.status === 'missing' && (
                                            <div className="mt-4">
                                                <ReactMarkdown>{loginContent.missing_invoice}</ReactMarkdown>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : loginContentResponse.isError ? (
                            <div className="grid items-center h-full md:h-4/5 ">
                                <div>
                                    <h3>Oj då! Nu gick nåt snett hos oss...</h3>
                                    <br />
                                    Försök att ladda om sidan men skulle det inte fungera så kan du alltid kontakta oss på 031-719 99 20
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="hidden md:block h-full w-full  bg-fg-primary">
                {loginStyleBgImg && <div className="h-full w-full bg-no-repeat bg-cover opacity-20" style={loginStyleBgImg}></div>}
            </div>
        </div>
    );
}
