type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    icon?: React.ReactNode;
};

function Button({ children, icon, className, disabled, ...rest }: ButtonProps) {
    return (
        <button
            className={`bg-fg-primary border-fg-primary text-white border-2 p-2 min-w-[153px] sm:min-h-[57px] rounded-full ${className} ${
                disabled ? 'border-opacity-0 bg-opacity-30 text-white' : 'hover:bg-fg-primary-400 hover:border-fg-primary-400'
            } `}
            disabled={disabled}
            {...rest}
        >
            {icon && <i className="icon">{icon}</i>}
            {children}
        </button>
    );
}
export default Button;
