import { Api } from '../../utils/api';

const baseUrl = process.env.REACT_APP_KEEROS_API_URL;

export async function getInvoice(request: InvoiceRequest): Promise<InvoiceResponse> {
    const url = `${baseUrl}/getinvoicestatus?invoiceId=${request.id}&isAtivoCore=${request.isAtivoCore}`;

    try {
        const response = await Api.get<InvoiceResponse>(url);
        return response;
    } catch (error) {
        return {
            success: false,
            error: JSON.stringify(error)?.toString(),
        } as InvoiceResponse;
    }
}

export async function getInvoiceDocument(request: InvoiceDocumentRequest): Promise<InvoiceDocumentResponse> {
    const url = `${baseUrl}/invoicedocument?documentId=${request.documentId}&organizationNumber=${request.organizationNumber}&base64EncodedString=true`;
    try {
        const response = await Api.get<InvoiceDocumentResponse>(url);
        response.success = true;
        return response;
    } catch (error) {
        return {
            success: false,
            error: error,
        } as InvoiceDocumentResponse;
    }
}

export interface InvoiceDocumentRequest {
    documentId: string;
    organizationNumber: string;
    base64EncodedString: boolean;
}

export interface InvoiceDocumentResponse {
    mime: string;
    data: any;
    base64Encoded: string;
    success: boolean;
    error: string;
}

export interface InvoiceRequest {
    id: string;
    isAtivoCore: boolean;
}

export interface InvoiceResponse {
    success: boolean;
    error: string;
    id: string;
    invoice: Invoice;
    issuerName: string;
    issuerOrganizationNumber: string;
}

export interface InvoiceDocument {
    documentId: string;
    documentType: string;
    documentDate: string;
}

export interface Invoice {
    recepient: Recipient;
    issuer: Issuer;
    invoiceNumber: string;
    invoiceDate: string;
    ocr: string;
    invoiceExpiryDate: string;
    bankgiro: string;
    remainingToPay: string;
    factoringStatus: string;
    statuses: InvoiceStatus[];
    payments: Payment[];
    documents: InvoiceDocument[];
}

export interface Payment {
    amount: string;
    paymentDate: string;
    paymentMethod: {
        name: string;
    };
}

export interface InvoiceStatus {
    state: string;
    timestamp: string;
}

export interface Recipient {
    name: string;
}

export interface Issuer {
    name: string;
}
