import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalContent, ResolveImageUrl, ResolvedImage } from '../../../utils/contentApi';
import { useAppDispatch } from '../../../app/hooks';
import { selectInvoice, getInvoiceDocumentAsync, setSelectedDocumentType } from '../invoiceSlice';
import { InvoiceContentDataAttributes } from '../../../utils/contentApi';
import { HistoryStatesWithPayment } from '../historyStates';
import { formatDate } from '../../../utils/formatting';
import { downloadPdf } from '../../../utils/download';

export default function History({
    invoiceContent,
    globalContent,
    historyStates,
}: {
    invoiceContent: InvoiceContentDataAttributes | null;
    globalContent: GlobalContent | undefined;
    historyStates: Array<HistoryStatesWithPayment> | null;
}) {
    const invoiceState = useSelector(selectInvoice);
    const [arrowIcon, setArrowIcon] = useState<ResolvedImage | null>(null);
    const [downloadIcon, setDownloadIcon] = useState<ResolvedImage | null>(null);
    const [downloadRequest, setDownloadRequest] = useState<boolean>(false);
    const [downloadRequestFailed, setDownloadRequestFailed] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (globalContent) {
            const arrowIcon = ResolveImageUrl(globalContent.data.attributes?.arrow);
            const downloadIcon = ResolveImageUrl(globalContent.data.attributes?.download);
            if (arrowIcon.exists) setArrowIcon(arrowIcon);
            if (downloadIcon.exists) setDownloadIcon(downloadIcon);
        }
    }, [globalContent]);

    useEffect(() => {
        if (invoiceState.isLoading) {
            return;
        }
        if (downloadRequest) {
            setDownloadRequestFailed(undefined);
            if (invoiceState.selectedDocument) {
                downloadPdf(invoiceState);
                setDownloadRequest(false);
            } else {
                setDownloadRequestFailed(invoiceState!.selectedDocumentType);
            }
        }
    }, [invoiceState, downloadRequest]);

    const getDocument = (documentType: string | undefined, download: boolean) => {
        if (documentType) {
            let invoiceDoc = invoiceState.invoice?.documents.find((s) => s.documentType === documentType);
            if (invoiceDoc) {
                dispatch(
                    getInvoiceDocumentAsync({
                        documentId: invoiceDoc.documentId,
                        organizationNumber: invoiceState.issuerOrganizationNumber!,
                        base64EncodedString: true,
                    })
                );
                dispatch(setSelectedDocumentType(invoiceDoc.documentType));
                if (download) setDownloadRequest(true);
            }
        }
    };

    return historyStates && historyStates.length > 0 ? (
        <>
            <div className="grid mx-10">
                <h3>{invoiceContent?.history_sub_header}</h3>
            </div>
            {invoiceState.invoice && (
                <div className="grid grid-cols-[auto__1fr__1fr__auto] grid-flow-col gap-x-5 items-center mx-6">
                    {historyStates ? (
                        <>
                            {historyStates.map((state: HistoryStatesWithPayment | null, index) => {
                                return state != null ? (
                                    <div className="contents" key={index}>
                                        <div className="row-span-2 col-start-1">
                                            {state.item?.icon_list_status.data ? (
                                                <img
                                                    src={ResolveImageUrl(state.item?.icon_list_status).url!}
                                                    alt={state.item?.stateDescription}
                                                    className="w-5 h-5 mr-2"
                                                />
                                            ) : null}
                                        </div>
                                        <div className="col-start-2 font-semibold tracking-wider">{state.item?.stateDescription}</div>
                                        <div className="col-start-2 whitespace-nowrap">{formatDate(state.timestamp)}</div>
                                        <div className="col-start-3 row-span-2">{state.invoiceChangeAmount}</div>
                                        <div className="col-start-4 row-span-2">
                                            {state.item?.documentType?.DocumentTypes ? (
                                                <button>
                                                    {downloadIcon ? (
                                                        <img
                                                            className="lg:hidden"
                                                            src={downloadIcon.url!}
                                                            alt={downloadIcon.title}
                                                            onClick={() => {
                                                                getDocument(state.item!.documentType.DocumentTypes, true);
                                                            }}
                                                        />
                                                    ) : null}
                                                    {arrowIcon ? (
                                                        <img
                                                            className="hidden lg:inline-block"
                                                            src={arrowIcon.url!}
                                                            alt={arrowIcon.title}
                                                            onClick={() => {
                                                                getDocument(state.item!.documentType.DocumentTypes, false);
                                                            }}
                                                        />
                                                    ) : null}
                                                </button>
                                            ) : null}
                                        </div>
                                        {state.item!.documentType?.DocumentTypes &&
                                        downloadRequestFailed &&
                                        downloadRequestFailed === state.item!.documentType?.DocumentTypes ? (
                                            <div className="col-start-2 col-span-4 text-sm mt-2 text-red-600">
                                                {invoiceContent?.no_document ?? 'Dokumentet hittades inte'}
                                            </div>
                                        ) : null}
                                        <div className="col-start-1 col-span-4 bg-fg-primary-100 h-1 my-4"></div>
                                    </div>
                                ) : null;
                            })}
                        </>
                    ) : null}
                </div>
            )}
        </>
    ) : null;
}
