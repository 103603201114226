export const formatCurrency = (amount: number | string) => {
    return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(Number(amount));
};

export const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
};
