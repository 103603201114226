import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { invoiceExists, LoginRequest } from './loginAPI';

export interface LoginState {
    status: 'init' | 'needsExternalLogin' | 'success' | 'missing' | 'error' | 'loginComplete';
    isLoading: boolean;
    transactionId?: string;
    invoiceId?: string;
}

const initialState: LoginState = {
    status: 'init',
    isLoading: false,
};

export const invoiceExistsAsync = createAsyncThunk('login/invoiceExists', async (request: LoginRequest) => {
    const response = await invoiceExists(request);
    return response;
});

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logout: () => {
            sessionStorage.removeItem('invoicestate');
            return initialState;
        },
        setBankIdLogin: (state, action) => {
          state.status = 'success';
          state.transactionId = action.payload;
        },
        setLoginComplete: (state) => {
          state.status = 'loginComplete';
        },
        setLoginFailed: (state) => {
          state.status = 'error';
          state.transactionId =''
          state.isLoading = false;
          state.invoiceId = '';
      },
    },
    extraReducers: (builder) => {
        builder
            .addCase(invoiceExistsAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(invoiceExistsAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.missing) {
                    state.status = 'missing';
                } else if (action.payload.isPerson) {
                    state.status = 'needsExternalLogin';
                } else if (action.payload.error) {
                    state.status = 'error';
                } else if (action.payload.invoiceId) {
                    state.status = 'success';
                    state.invoiceId = action.payload.invoiceId;
                } else {
                    state.status = 'init';
                }
            })
            .addCase(invoiceExistsAsync.rejected, (state) => {
                state.isLoading = false;
                state.status = 'error';
            });
    },
});

export const { logout, setBankIdLogin, setLoginComplete, setLoginFailed } = loginSlice.actions;

export const selectLogin = (state: RootState) => state.login;

export default loginSlice.reducer;
