import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './utils/oidcConfig';
import './styles/global.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <AuthProvider {...oidcConfig} >
        <Provider store={store}>
            <App />
        </Provider>
    </AuthProvider>
);
