import { UserManager } from 'oidc-client-ts';
import { oidcConfig } from './oidcConfig';

async function SendAsync<TResponse>(
    url: string,
    // `RequestInit` is a type for configuring
    // a `fetch` request. By default, an empty object.
    config: RequestInit = {}

    // This function is async, it will return a Promise:
): Promise<TResponse> {
    // Inside, we call the `fetch` function with
    // a URL and config given:

    return (
        fetch(url, await appendAuthorizationToken(config))
            // When got a response call a `json` method on it
            .then((response) => {
                if (response.ok) return response.json();
                else return Promise.reject(response);
            })
            // and return the result data.
            .then((data) => data as TResponse)
            .catch((error) => {
                throw error;
            })
    );

    // We also can use some post-response
    // data-transformations in the last `then` clause.
}

async function appendAuthorizationToken(config: RequestInit): Promise<RequestInit> {
    await new UserManager(oidcConfig)
        .getUser()
        .then((user) => {
            if (user?.access_token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${user?.access_token}`,
                };
            }
        })
        .catch((error) => {
            throw new Error("Error getting user: " + error);
        });
    return config;
}
export const Api = {
    get: async <TResponse>(url: string) => await SendAsync<TResponse>(url, { method: 'GET', headers: {} }),

    // Using `extends` to set a type constraint:
    post: async <TBody extends BodyInit, TResponse>(url: string, body: TBody) =>
        await SendAsync<TResponse>(url, { method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' }, body }),
};
