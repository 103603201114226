import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { InvoiceContentDataAttributes, useGlobalContentQuery, useInvoiceContentQuery, useHistoryStateQuery } from '../../utils/contentApi';
import { logout } from '../login/loginSlice';
import Details from './components/Details';
import History from './components/History';
import { getInvoiceAsync, selectInvoice, getInvoiceDocumentAsync, setSelectedDocumentType, clearSelectedDocument } from './invoiceSlice';
import { selectLogin } from '../login/loginSlice';
import { getStatesWithPayments, HistoryStatesWithPayment, ItemType } from './historyStates';
import MobileMenuDropDown from './components/MobileMenuDropDown';
import { useAuth } from 'react-oidc-context';
import Button from '../../components/button/Index';
import { RenderImage } from '../../components/image';
import ReactMarkdown from 'react-markdown';
import { Document, Page, pdfjs } from 'react-pdf';
import Spinner from '../../utils/Spinner';
import { downloadPdf } from '../../utils/download';
import { useNavigate, useParams } from 'react-router-dom';

export default function Invoice() {
    const invoiceState = useSelector(selectInvoice);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { invoiceObject } = useParams();
    const globalContentResponse = useGlobalContentQuery();
    const invoiceContentResponse = useInvoiceContentQuery();
    const historyStateContent = useHistoryStateQuery();
    const loginState = useSelector(selectLogin);
    const [invoiceContent, setInvoiceContent] = useState<null | InvoiceContentDataAttributes>(null);
    const [historyStates, setHistoryStatesWithPayments] = useState<Array<HistoryStatesWithPayment> | null>([]);
    const [lastState, setLastState] = useState<HistoryStatesWithPayment | null | undefined>(null);
    const auth = useAuth();
    const [historyActive, setHistoryActive] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [maxiumPageNumber, setMaxiumPageNumber] = useState(null);
    const defaultZoomLevel = window.innerWidth > 2000 ? 1.3 : window.innerHeight > 1000 ? 1.0 : 0.8;
    const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);
    const [loadedPdf, setLoadedPdf] = useState(false);

    useEffect(() => {
        if (invoiceContentResponse.data && invoiceContentResponse.isSuccess) {
            // get the attributes with the latest published date
            const content = invoiceContentResponse.data.data;
            setInvoiceContent(content?.attributes ?? null);
        }
    }, [invoiceContentResponse.data, invoiceContentResponse.isSuccess]);

    useEffect(() => {
        if (invoiceState?.id || invoiceObject) {
            const parsedInvoiceObject = invoiceState?.id ? null : JSON.parse(atob(invoiceObject!));
            let id = parsedInvoiceObject ? parsedInvoiceObject.id : invoiceState.id;
            let isAtivoCore = parsedInvoiceObject ? parsedInvoiceObject.isAtivoCore : invoiceState.isAtivoCore;
            dispatch(getInvoiceAsync({ id: id, isAtivoCore: isAtivoCore }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceState.id, invoiceObject]);

    useEffect(() => {
        if (invoiceState.invoice?.documents) {
            let invoiceDoc = invoiceState.invoice?.documents.find((s) => s.documentType === 'Faktura');
            if (invoiceDoc && invoiceState.issuerOrganizationNumber) {
                dispatch(clearSelectedDocument());
                dispatch(
                    getInvoiceDocumentAsync({
                        documentId: invoiceDoc.documentId,
                        organizationNumber: invoiceState.issuerOrganizationNumber,
                        base64EncodedString: true,
                    })
                );
                dispatch(setSelectedDocumentType(invoiceDoc.documentType));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceState.invoice?.documents]);

    useEffect(() => {
        if (!historyStateContent.isSuccess || !invoiceState.invoice || (historyStates && historyStates.length > 0)) {
            return;
        }
        const states = getStatesWithPayments(invoiceState, historyStateContent.data);
        if (states && states.length > 0) {
            setHistoryStatesWithPayments(states);
            setLastState(states.find((s) => s.itemType === ItemType.InvoiceState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceState, historyStates, historyStateContent.isSuccess]);

    useEffect(() => {
        if (invoiceState.error || (!auth.isLoading && !auth.user?.access_token)) {
            navigate('/unauthorized', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isLoading, auth.user, invoiceState.error]);

    useEffect(() => {
        if (invoiceState.isLoading) {
            setLoadedPdf(false);
            setZoomLevel(defaultZoomLevel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceState.selectedDocumentType]);

    const handleLogout = () => {
        dispatch(logout());
        auth.signoutRedirect();
    };
    const setZoom = (increase: boolean) => {
        if (increase && zoomLevel < 2) {
            setZoomLevel(parseFloat((zoomLevel + 0.1).toFixed(1)));
        } else if (!increase && zoomLevel > 0.1) {
            setZoomLevel(parseFloat((zoomLevel - 0.1).toFixed(1)));
        }
    };
    useEffect(() => {
        // we need to put invoice id and transaction id on the payload when token is expiring - otherwise the renvew will not work
        // addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent({ extraQueryParams: { invoiceId: invoiceState.id || '', tid: loginState.transactionId || '' } });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.events, auth.signinSilent]);
    const onDocumentLoadSuccess = (pdf: any) => {
        setMaxiumPageNumber(pdf.numPages);
        setLoadedPdf(true);
    };

    return (
        <div className="grid grid-rows-[auto__1fr] absolute inset-0 lg:overflow-x-hidden">
            <div className="grid grid-cols-[1fr__auto] py-4 pl-4 grid-flow-col bg-fg-primary w-screen ">
                <div className="grid items-center">
                    <RenderImage media={globalContentResponse?.data?.data?.attributes?.fg_logo.data} imageVariant={1} className="w-16 h-16" />
                </div>
                <MobileMenuDropDown handleLogout={handleLogout} logoutBtnText={invoiceContent?.log_out_btn} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[360px__1fr] w-[screen] h-full drop-shadow-xl ">
                <div className="flex flex-col drop-shadow-xl">
                    <div className="drop-shadow-lg bg-fg-primary-100 w-full">
                        <div className="grid grid-cols-[auto__1fr] gap-8 ml-10 py-4 items-center h-[75px]">
                            {lastState ? (
                                <>
                                    <RenderImage media={lastState.item?.icon_main_status} className="w-12 h-12" />
                                    <div className="font-bold text-lg text-fg-primary">{lastState?.item?.stateDescription}</div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-8 bg-fg-primary-50 text-fg-primary ">
                        <div className="col-start-1 mx-auto lg:ml-10">
                            <button className="font-bold pl-2" onClick={() => setHistoryActive(false)}>
                                {invoiceContent?.info_header}
                                <div
                                    className={`bg-fg-primary h-[5px] -mx-2 border-transparent rounded-lg transform duration-500 ${
                                        !historyActive ? ' opacity-1' : 'opacity-0'
                                    }`}
                                ></div>
                            </button>
                        </div>
                        <div className="col-start-3">
                            <button className="w-min font-semibold" onClick={() => setHistoryActive(true)}>
                                {invoiceContent?.history_header}
                                <div
                                    className={`bg-fg-primary h-[5px] -mx-2  border-transparent rounded-lg transform duration-500 ${
                                        historyActive ? ' opacity-1' : 'opacity-0'
                                    }`}
                                ></div>
                            </button>
                        </div>
                    </div>
                    <div className="grow bg-fg-primary-50 text-fg-primary pb-4 overflow-x-auto h-80 scroller overscroll-contain">
                        <div className="mb-8">
                            {historyActive ? (
                                <History invoiceContent={invoiceContent} globalContent={globalContentResponse.data} historyStates={historyStates} />
                            ) : (
                                <Details invoiceContent={invoiceContent} globalContent={globalContentResponse.data} />
                            )}
                        </div>
                    </div>
                    <div className="hidden lg:grid bg-fg-primary-400 py-2 w-full place-content-center">
                        <Button onClick={handleLogout} className="bg-white text-fg-primary hover:bg-gray-100 ">
                            {invoiceContent?.log_out_btn}
                        </Button>
                    </div>
                </div>
                {!invoiceState.documentError && invoiceState.selectedDocument ? (
                    <div className={`hidden lg:flex my-auto`}>
                        <div className="grid grid-cols-[auto__1fr__auto] mx-auto ">
                            {invoiceState.selectedDocument.length > 0 ? (
                                <>
                                    {maxiumPageNumber && maxiumPageNumber > 1 && (
                                        <div
                                            className={`border-1 font-semibold bg-white place-self-center rounded-full h-12 w-12 mr-8 transition ease-in-out duration-1000  ${
                                                pageNumber < 2 ? ' text-gray-200' : 'text-fg-primary border-fg-primary'
                                            } ${loadedPdf ? 'opacity-100' : 'opacity-0'} `}
                                        >
                                            <button
                                                disabled={pageNumber < 2}
                                                onClick={() => setPageNumber(pageNumber - 1)}
                                                className="flex items-center justify-center h-full w-full text-sm"
                                            >
                                                &lt;
                                            </button>
                                        </div>
                                    )}
                                    <div>
                                        <div
                                            className={`flex gap-4 my-2 items-start transition ease-in-out duration-1000  ${
                                                loadedPdf ? 'visible opacity-100' : 'invisible opacity-0'
                                            } `}
                                        >
                                            <button className="w-4 h-4 hover:opacity-60" onClick={() => downloadPdf(invoiceState)}>
                                                <RenderImage media={globalContentResponse.data?.data.attributes.download} />
                                            </button>
                                            <button className="start w-4 h-4 hover:opacity-60" onClick={() => setZoom(false)}>
                                                <RenderImage media={globalContentResponse.data?.data.attributes.minus} />
                                            </button>
                                            <button className="w-4 h-4 hover:opacity-60" onClick={() => setZoom(true)}>
                                                <RenderImage media={globalContentResponse.data?.data.attributes.plus} />
                                            </button>
                                            <div className="text-xs ml-auto">
                                                {pageNumber} / {maxiumPageNumber}
                                            </div>
                                        </div>

                                        <Document
                                            file={invoiceState.selectedDocument}
                                            error={invoiceContent?.no_document}
                                            loading={<Spinner />}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <div className="flex h-[29.7cm] w-[21cm] xl:h-[88vh] xl:w-[68vh] my-auto bg-white overflow-auto overscroll-contain drop-shadow-xl border-1">
                                                <Page
                                                    className={`my-auto mx-auto transition-all ease-in-out duration-500 ${
                                                        loadedPdf ? 'opacity-100' : 'opacity-0'
                                                    } `}
                                                    pageNumber={pageNumber}
                                                    error={invoiceContent?.no_document}
                                                    renderAnnotationLayer={false}
                                                    renderTextLayer={false}
                                                    scale={zoomLevel}
                                                    height={842}
                                                ></Page>
                                            </div>
                                        </Document>
                                    </div>

                                    {maxiumPageNumber && maxiumPageNumber > 1 && (
                                        <div
                                            className={`border-1 font-semibold bg-white place-self-center rounded-full h-12 w-12 ml-8 transition ease-in-out duration-1000  ${
                                                maxiumPageNumber === pageNumber ? ' text-gray-200' : 'text-fg-primary-400 border-fg-primary-400'
                                            } ${loadedPdf ? 'opacity-100' : 'opacity-0'} `}
                                        >
                                            <button
                                                disabled={maxiumPageNumber === pageNumber}
                                                onClick={() => setPageNumber(pageNumber + 1)}
                                                className="flex items-center justify-center h-full w-full text-sm"
                                            >
                                                &gt;
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="p-12 h-full">
                                    <ReactMarkdown>{invoiceContent?.no_document ?? 'Dokumentet hittades inte'}</ReactMarkdown>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    !invoiceState.isLoading && (
                        <div className="hidden lg:flex my-auto mx-auto">
                            <ReactMarkdown>{invoiceContent?.no_document ?? 'Dokumentet hittades inte'}</ReactMarkdown>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}
